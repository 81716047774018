import { Order, ORDER_STATUSES } from "@/interface/orders/Order"
import { defaultFormatter } from "@/pages/orders/utils/useOrdersSorting"
import { useSettingsStore } from "@/store/Settings"
import axios, { AxiosResponse } from "axios"
import { defineStore } from "pinia"
import { useOrdersStore } from "@/store/Orders"
import router from "@/router"

export interface KitchenScreenState {
    orders: Order[]
    loading: boolean
    dataReady: boolean
    error: string | null
    pagination: Pagination
    layout: string
    filteredOrders?: Order[] | undefined
}

type Pagination = {
    current_page: number
    last_page: number
    total: number
    per_page: number
}

enum Layout {
    Grid = "grid",
    Columns = "columns",
}

export const kitchenScreenStore = defineStore("kitchenScreenStore", {
    state: (): KitchenScreenState => ({
        orders: [],
        loading: false,
        dataReady: false,
        error: null,
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page:
                (localStorage.getItem("kitchenLayout") || Layout.Grid) ===
                Layout.Grid
                    ? 10
                    : 5,
        },
        layout: localStorage.getItem("kitchenLayout") || Layout.Grid,
    }),
    getters: {
        isActive: () => {
            return router.currentRoute.value.path.includes("kitchen-screen")
        },
        filteredOrders: (state): Order[] => {
            const direct: boolean =
                useSettingsStore().settings.kitchenscreen_send_when === "direct"
            const directSources: string[] | undefined =
                useSettingsStore().settings.kitchenscreen_send_when_direct?.split(
                    " "
                )
            const inDirectSetting = (source: string): boolean =>
                (directSources ?? []).includes(source)

            const isDirect = (order: Order): boolean => {
                if (order.source.type === "online") {
                    return (
                        inDirectSetting(order.source.info) ||
                        ((!order.source.info || false) &&
                            inDirectSetting("online")) ||
                        (["ios", "android"].includes(order.source.info) &&
                            inDirectSetting("app"))
                    )
                }
                return inDirectSetting(order.source.type)
            }

            return state.orders.filter(
                (order: Order): boolean =>
                    order.status === ORDER_STATUSES[1] || // "pending"
                    (direct &&
                        order.status === ORDER_STATUSES[0] &&
                        isDirect(order)) // "init"
            )
        },
        paginatedOrders: (state): Order[] => {
            return (state.filteredOrders ?? []).slice(
                (state.pagination.current_page - 1) * state.pagination.per_page,
                state.pagination.current_page * state.pagination.per_page
            )
        },
    },
    actions: {
        async fetchOrders(): Promise<boolean> {
            try {
                if (!this.dataReady) {
                    this.loading = true
                    this.error = null
                }

                const url: string = useOrdersStore().fetchOrderUrl(
                    null,
                    [
                        "address",
                        "billing_address",
                        "id",
                        "client_id",
                        "delivery",
                        "nr",
                        "refnr",
                        "remarks",
                        "source",
                        "status",
                        "table_id",
                        "time_set",
                        "vat_rates",
                        // Next ones are needed for printing
                        "payment",
                        "discount",
                    ],
                    ["products", "table"],
                    [],
                    true,
                    true
                )

                const response: AxiosResponse<any> = await axios.get(url)

                this.orders = defaultFormatter(response.data.data)

                this.pagination.total = this.filteredOrders.length
                this.pagination.last_page = Math.ceil(
                    this.filteredOrders.length / this.pagination.per_page
                )

                this.dataReady = true
                return true
            } catch (e: any) {
                this.error = e.message || "Failed to fetch orders."
                return false
            } finally {
                this.loading = false
            }
        },
        async updateOrder(orderId: number): Promise<void> {
            const updatedOrder: Order = await useOrdersStore().fetchObject(
                orderId
            )
            const indexOrder: number = this.orders.findIndex(
                (order: Order): boolean => order.id === updatedOrder.id
            )
            if (indexOrder > -1) {
                this.orders[indexOrder] = updatedOrder
            }

            this.updatePagination()
        },
        async placedOrder(orderId: number): Promise<void> {
            const newOrder: Order = await useOrdersStore().fetchObject(orderId)

            const indexOrder: number = this.orders.findIndex(
                (order: Order): boolean => order.id === newOrder.id
            )

            // An updated table order arrives here
            if (indexOrder !== -1 && newOrder.table_id) {
                this.orders[indexOrder] = newOrder
                this.updatePagination()
                return
            }

            const today: Date = new Date()
            const startOfDay: Date = new Date(today.setHours(6, 0, 0, 0))
            const endOfDay: Date = new Date(today.setHours(23, 59, 59, 999))
            endOfDay.setTime(endOfDay.getTime() + 6 * 60 * 60 * 1000)
            const orderDate: Date = new Date(newOrder.created_at)

            if (
                orderDate >= startOfDay &&
                orderDate <= endOfDay &&
                (newOrder.payment.result === null ||
                    newOrder.payment.result.toLowerCase() !== "credit") &&
                (newOrder.payment.status === "paid" ||
                    (["counter", "qr_table"].includes(newOrder.source.type) &&
                        newOrder.table_id > 0))
            ) {
                this.orders.push(newOrder)

                this.updatePagination()
            }
        },
        updateLayout(): void {
            const layoutMap: Record<Layout, Layout> = {
                [Layout.Grid]: Layout.Columns,
                [Layout.Columns]: Layout.Grid,
            }

            const layoutValue: Layout =
                layoutMap[this.layout as Layout] || Layout.Grid

            this.layout = layoutValue
            localStorage.setItem("kitchenLayout", layoutValue)

            this.updatePagination()
        },
        updatePagination(): void {
            this.pagination.per_page = this.layout === Layout.Grid ? 10 : 5
            this.pagination.last_page = Math.ceil(
                this.filteredOrders.length / this.pagination.per_page
            )

            this.pagination.current_page = Math.min(
                this.pagination.current_page,
                this.pagination.last_page
            )
        },
        removeOrderWhenDone(orderId: number): void {
            const index: number = this.orders.findIndex(
                (order: Order): boolean => order.id === orderId
            )

            if (index >= 0) {
                this.orders.splice(index, 1)
            }
        },
    },
})
