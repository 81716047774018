<template>
    <template v-if="!isKitchenScreen">
        <div class="menu-list">
            <Component
                v-for="menuItem in firstItems"
                :key="menuItem.text"
                :to="{ name: menuItem.name }"
                @click="onClick(menuItem.name)"
                class="menu-item"
                :is="splitPaymentStore.isPartiallyPaid ? 'span' : 'router-link'"
                :class="[
                    menuItem.name,
                    { current: menuItem.name === currentRoute },
                    splitPaymentStore.isPartiallyPaid
                        ? '!cursor-not-allowed disabled'
                        : '',
                ]"
            >
                <span
                    v-if="menuItem.name === 'archive' && futureOrderCount > 0"
                    class="statistic"
                    >{{ futureOrderCountLabel }}</span
                >
                <IconBase :icon="menuItem.icon"></IconBase>
                <span class="text">{{ translate(menuItem.text) }}</span>
            </Component>
            <div
                ref="more_ref"
                class="more menu-item cursor-pointer"
                :class="[
                    {
                        current: otherSelected,
                        disabled: isOffline,
                    },
                    splitPaymentStore.isPartiallyPaid
                        ? '!cursor-not-allowed disabled'
                        : '',
                ]"
                @click.stop="
                    !splitPaymentStore.isPartiallyPaid
                        ? (dropdownOpen = !dropdownOpen)
                        : null
                "
            >
                <IconBase icon="menu-dots"></IconBase>
                <span class="text">{{ translate("more") }}</span>
            </div>
        </div>
        <div v-if="dropdownOpen" class="dropdown-menu open" ref="dropdown_ref">
            <Component
                v-for="menuItem in otherItems"
                :key="menuItem.text"
                :to="{ name: menuItem.name }"
                class="menu-item"
                :class="[
                    menuItem.name,
                    { current: menuItem.name === currentRoute },
                    splitPaymentStore.isPartiallyPaid
                        ? '!cursor-not-allowed disabled'
                        : '',
                ]"
                @click="dropdownOpen = false"
                :is="splitPaymentStore.isPartiallyPaid ? 'span' : 'router-link'"
            >
                <IconBase :icon="menuItem.icon"></IconBase>
                <span class="text">{{ translate(menuItem.text) }}</span>
            </Component>
        </div>
    </template>

    <UserData v-if="multiLocation.isActive" />

    <div class="language-selector">
        <LanguagePicker icon="web" :transparent="false" hide-selected />
    </div>
    <div class="menu-list-right">
        <CheckSwitch
            class="lock"
            :checked="apiStore.isSudoMode"
            @update:checked="toggleSudoMode"
            check-icon="unlock"
            uncheck-icon="lock"
            data-testid="sudo-mode"
            :disabled="isOffline || splitPaymentStore.isPartiallyPaid"
        />
        <FontSizeModifier />
        <ButtonBase
            variant="text"
            :disabled="isOffline"
            class="messages cursor-pointer"
            @click="obMessages.showModal = true"
        >
            <IconBase icon="envelope" class="envelop-icon"></IconBase>
            <IconBase
                icon="circle-filled"
                class="unread-messages animate-pulse-object"
                v-if="obMessages.unreadMessageIds.length"
            />
        </ButtonBase>
        <div
            v-if="updatesStore().hasRiceCookerUpdate"
            class="updates cursor-pointer"
            @click="
                !splitPaymentStore.isPartiallyPaid
                    ? updatesStore().setRiceCookerUpdateModalIsVisible(true)
                    : null
            "
            :class="
                splitPaymentStore.isPartiallyPaid ? '!cursor-not-allowed' : ''
            "
        >
            <IconBase icon="sync" class="sync-icon"></IconBase>
            <IconBase
                icon="circle-filled"
                class="new-update animate-pulse-object"
            />
        </div>
    </div>
    <ButtonBase
        url="https://foodticketnl.my.site.com/foodticketsupport"
        :is-external-url="true"
        target="_blank"
        class="helpcenter cursor-pointer flex flex-col flex-nowrap"
        variant="text"
        :disabled="isOffline"
    >
        <div class="helpcenter-menu-item">
            <IconBase icon="helpcenter" class="helpcenter-icon" />
            <span class="text">{{ translate("helpcenter") }}</span>
        </div>
    </ButtonBase>
    <UserData v-if="!multiLocation.isActive" />
    <MessageModal
        v-if="obMessages.showModal"
        @close="obMessages.showModal = false"
    />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue"
import FontSizeModifier from "@/ui-elements/page-header/FontSizeModifier.vue"
import { mainMenuStructure } from "@/pageStructure/mainMenuStructure"
import { useRoute, useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { useClickAway } from "@/utils/useClickAway"
import { useAPIStore } from "@/store/API"
import { disableSudoMode } from "@/services/auth/sudoMode"
import MessageModal from "@/ui-elements/message-box/MessageModal.vue"
import UserData from "@/ui-elements/page-header/UserData.vue"
import { usePosMenusStore } from "@/store/PosMenus"
import { useCartStore } from "@/store/cart/Cart"
import { usePOSStore } from "@/store/POS"
import { dataHydration } from "@/services/DataHydrationService"
import { useOBMessagesStore } from "@/store/OBMessage"
import LanguagePicker from "@/ui-elements/LanguagePicker.vue"
import { useUserStore } from "@/store/User"
import { checkPath } from "@/ui-elements/menu/utils/useCheckPath"
import { useOrdersStore } from "@/store/Orders"
import { useSplitPaymentStore } from "@/store/SplitPayment"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { offlineModeStore } from "@/store/offlineMode"
import { updatesStore } from "@/store/Updates"
import { multiLocationStore } from "@/store/MultiLocation"

export default defineComponent({
    name: "DesktopMainMenu",
    components: {
        ButtonBase,
        IconBase,
        CheckSwitch,
        FontSizeModifier,
        MessageModal,
        UserData,
        LanguagePicker,
    },
    setup() {
        const { t: translate } = useI18n()

        const router = useRouter()
        const route = useRoute()
        const apiStore = useAPIStore()
        const userStore = useUserStore()
        const obMessages = useOBMessagesStore()
        const orderStore = useOrdersStore()
        const splitPaymentStore = useSplitPaymentStore()

        const futureOrderCount = computed(() => orderStore.futureOrderCount)
        // limit displayed label to 99. If over 99, show 99+
        const futureOrderCountLabel = computed(() =>
            orderStore.futureOrderCount <= 99
                ? orderStore.futureOrderCount
                : "99+"
        )

        const menuItems = computed(() =>
            mainMenuStructure.filter((item) => {
                const archiveMode = !!Number(userStore.user.is_read_only)
                const addPos = !(item.name === "pos" && archiveMode)
                const addOrders = !(item.name === "orders" && archiveMode)
                return addPos && addOrders
            })
        )
        const firstItems = computed(() => menuItems.value.slice(0, 4))
        const otherItems = computed(() => menuItems.value.slice(4))

        const dropdownOpen = ref(false)
        const dropdown_ref = ref()

        const currentRoute = computed(
            () => router.currentRoute.value.meta.name as string
        )

        const isKitchenScreen = computed(() => checkPath("/kitchen-screen"))
        const showFlyerImage = computed(
            () => router.currentRoute.value.name === "flyer"
        )

        const otherSelected = computed(() => {
            return otherItems.value
                .map((_) => _.name)
                .includes(currentRoute.value)
        })

        useClickAway(dropdown_ref, () => (dropdownOpen.value = false))

        const toggleSudoMode = async (value: boolean) => {
            if (!value) {
                // disable sudo mode
                apiStore.disableSudoMode()
                await disableSudoMode()
                return
            }

            // show pincode modal to enable sudo mode
            const APIStore = useAPIStore()
            APIStore.setRequirePincode(true).catch(() => {})
        }

        const posMenusStore = usePosMenusStore()
        const cartStore = useCartStore()
        const posStore = usePOSStore()

        const onClick = (routeName: string) => {
            if (splitPaymentStore.isPartiallyPaid) {
                return
            }

            // reset the pos page when click on it the second time
            if (routeName === "pos" && route.name === "pos") {
                posStore.posBack()
                posMenusStore.resetSelection()
                posMenusStore.resetSelectedProduct()
                cartStore.clearCurrentCart()
            }

            // forces orders to load when clicking on order again
            if (routeName === "orders" && route.name === "orders") {
                dataHydration.hydrateModule("orders", true)
            }
        }

        return {
            isOffline: offlineModeStore().isOffline,
            firstItems,
            otherItems,
            currentRoute,
            translate,
            otherSelected,
            dropdownOpen,
            toggleSudoMode,
            apiStore,
            showFlyerImage,
            obMessages,
            futureOrderCount,
            futureOrderCountLabel,
            open,
            onClick,
            updatesStore,
            menuItems,
            splitPaymentStore,
            isKitchenScreen,
            multiLocation: multiLocationStore(),
        }
    },
})
</script>

<style lang="scss" scoped>
.page-header {
    .dropdown-menu {
        position: absolute;
        top: calc(100% - 0.5rem);
        left: 27.5rem;
        background: $white;
        border-radius: $radius;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        padding: 0 $padding-l;
        box-shadow: $shadow;
        z-index: 20;

        &.open {
            max-height: 60rem;
        }

        .menu-item {
            padding: $padding $padding-m $padding 0;
            border-bottom: $normal-border;

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            &::after {
                content: none;
            }

            .text {
                margin-left: $margin-m;
                text-decoration: none;
                white-space: nowrap;
            }

            &:last-child {
                border: none;
            }
        }
    }

    .lock {
        margin-left: $margin-l;

        :deep(.icon-base) {
            color: $white;
            font-size: 0.75rem;
        }

        :deep(.input-field) {
            margin: 0;
        }
    }

    .messages {
        position: relative;
        margin: 0 $margin-l;
        display: flex;
        align-items: center;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .envelop-icon {
            font-size: 1.2rem;
        }

        .unread-messages {
            position: absolute;
            font-size: 0.75rem;
            color: $primary-color;
            top: -0.3rem;
            right: -0.35rem;
            border-radius: 2.5rem;
            background: $white;
        }
    }

    .updates {
        position: relative;
        margin: 0 $margin-l 0 0;
        display: flex;
        align-items: center;

        .sync-icon {
            font-size: 1.2rem;
        }

        .new-update {
            position: absolute;
            font-size: 0.75rem;
            color: $primary-color;
            top: -0.3rem;
            right: -0.35rem;
            border-radius: 2.5rem;
            background: $white;
        }
    }

    .menu-list-right {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: 90%;
            background: #ebebed;
            display: block;
            right: 0;
        }
    }

    .helpcenter {
        position: relative;
        margin: 0 $margin-l;
        align-items: center;
        max-height: 100%;
        .helpcenter-icon {
            font-size: 1.2rem;
        }
    }

    .helpcenter-menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        transition: all 0.18s ease-out;

        .text {
            font-size: $font-size-nav;
            transition: all 0.18s ease-out;
        }

        &:hover {
            color: $darker-gray;

            span {
                color: $darker-gray;
                transform: scale(1.1);
            }
        }
    }
}

.language-selector {
    margin-left: auto;
}
</style>
