import { Order, OrderStatusType } from "@/interface/orders/Order"

export type DataFormatter = (orders: Order[]) => Order[]
export const defaultFormatter = (orders: Order[]) => ordersSorter(orders)

export const ordersSorter = (apiOrders: Order[]) => {
    const initialOrders: Record<OrderStatusType, Order[]> = {
        init: [],
        pending: [],
        "pending-ready": [],
        enroute: [],
        delivered: [],
    }

    const sortedOrders: Record<
        "init" | "pending" | "pending-ready" | "enroute" | "delivered",
        Order[]
    > = apiOrders.reduce(
        (
            orders: Record<
                "init" | "pending" | "pending-ready" | "enroute" | "delivered",
                Order[]
            >,
            order: Order
        ): Record<
            "init" | "pending" | "pending-ready" | "enroute" | "delivered",
            Order[]
        > => {
            if (order.status in initialOrders) {
                orders[order.status] = [...orders[order.status], order]
                return orders
            }
            return orders
        },
        initialOrders
    )

    const statuses: OrderStatusType[] = Object.keys(
        sortedOrders
    ) as OrderStatusType[]
    statuses.forEach((status: OrderStatusType): void => {
        if (!sortedOrders[status].length) {
            return
        }
        sortedOrders[status].sort(
            (prevOrder: Order, nextOrder: Order): number => {
                // Just in case something went wrong, and we have orders with empty time_set
                if (!prevOrder.time_set && !nextOrder.time_set) {
                    return prevOrder.id - nextOrder.id
                }
                if (!prevOrder.time_set) {
                    return 1
                }
                if (!nextOrder.time_set) {
                    return -1
                }
                const prevOrderTime: string[] = prevOrder.time_set.split(":")
                const nextOrderTime: string[] = nextOrder.time_set.split(":")
                let prevHours: number = Number(prevOrderTime[0])
                if (prevHours >= 0 && prevHours < 6) {
                    prevHours += 24
                }
                let nextHours: number = Number(nextOrderTime[0])
                if (nextHours >= 0 && nextHours < 6) {
                    nextHours += 24
                }
                const newPrevTime: number = Number(
                    `${prevHours}${prevOrderTime[1] || "00"}`
                )
                const newNextTime: number = Number(
                    `${nextHours}${nextOrderTime[1] || "00"}`
                )
                const difference: number = newPrevTime - newNextTime
                if (difference === 0) {
                    return prevOrder.id - nextOrder.id
                } else {
                    // En-route and delivered orders are ordered from new to old
                    return (
                        (["enroute", "delivered"].includes(status) ? -1 : 1) *
                        difference
                    )
                }
            }
        )
    })

    return [
        ...sortedOrders.init,
        ...sortedOrders.pending,
        ...sortedOrders["pending-ready"],
        ...sortedOrders.enroute,
        ...sortedOrders.delivered,
    ]
}
