import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67927624"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-list" }
const _hoisted_2 = {
  key: 0,
  class: "statistic"
}
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = {
  key: 0,
  class: "dropdown-menu open",
  ref: "dropdown_ref"
}
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "language-selector" }
const _hoisted_8 = { class: "menu-list-right" }
const _hoisted_9 = { class: "helpcenter-menu-item" }
const _hoisted_10 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_UserData = _resolveComponent("UserData")!
  const _component_LanguagePicker = _resolveComponent("LanguagePicker")!
  const _component_CheckSwitch = _resolveComponent("CheckSwitch")!
  const _component_FontSizeModifier = _resolveComponent("FontSizeModifier")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_MessageModal = _resolveComponent("MessageModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isKitchenScreen)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstItems, (menuItem) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.splitPaymentStore.isPartiallyPaid ? 'span' : 'router-link'), {
                key: menuItem.text,
                to: { name: menuItem.name },
                onClick: ($event: any) => (_ctx.onClick(menuItem.name)),
                class: _normalizeClass(["menu-item", [
                    menuItem.name,
                    { current: menuItem.name === _ctx.currentRoute },
                    _ctx.splitPaymentStore.isPartiallyPaid
                        ? '!cursor-not-allowed disabled'
                        : '',
                ]])
              }, {
                default: _withCtx(() => [
                  (menuItem.name === 'archive' && _ctx.futureOrderCount > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.futureOrderCountLabel), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_IconBase, {
                    icon: menuItem.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate(menuItem.text)), 1)
                ]),
                _: 2
              }, 1032, ["to", "onClick", "class"]))
            }), 128)),
            _createElementVNode("div", {
              ref: "more_ref",
              class: _normalizeClass(["more menu-item cursor-pointer", [
                    {
                        current: _ctx.otherSelected,
                        disabled: _ctx.isOffline,
                    },
                    _ctx.splitPaymentStore.isPartiallyPaid
                        ? '!cursor-not-allowed disabled'
                        : '',
                ]]),
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                    !_ctx.splitPaymentStore.isPartiallyPaid
                        ? (_ctx.dropdownOpen = !_ctx.dropdownOpen)
                        : null
                ), ["stop"]))
            }, [
              _createVNode(_component_IconBase, { icon: "menu-dots" }),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate("more")), 1)
            ], 2)
          ]),
          (_ctx.dropdownOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherItems, (menuItem) => {
                  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.splitPaymentStore.isPartiallyPaid ? 'span' : 'router-link'), {
                    key: menuItem.text,
                    to: { name: menuItem.name },
                    class: _normalizeClass(["menu-item", [
                    menuItem.name,
                    { current: menuItem.name === _ctx.currentRoute },
                    _ctx.splitPaymentStore.isPartiallyPaid
                        ? '!cursor-not-allowed disabled'
                        : '',
                ]]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dropdownOpen = false))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconBase, {
                        icon: menuItem.icon
                      }, null, 8, ["icon"]),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translate(menuItem.text)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]))
                }), 128))
              ], 512))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.multiLocation.isActive)
      ? (_openBlock(), _createBlock(_component_UserData, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_LanguagePicker, {
        icon: "web",
        transparent: false,
        "hide-selected": ""
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_CheckSwitch, {
        class: "lock",
        checked: _ctx.apiStore.isSudoMode,
        "onUpdate:checked": _ctx.toggleSudoMode,
        "check-icon": "unlock",
        "uncheck-icon": "lock",
        "data-testid": "sudo-mode",
        disabled: _ctx.isOffline || _ctx.splitPaymentStore.isPartiallyPaid
      }, null, 8, ["checked", "onUpdate:checked", "disabled"]),
      _createVNode(_component_FontSizeModifier),
      _createVNode(_component_ButtonBase, {
        variant: "text",
        disabled: _ctx.isOffline,
        class: "messages cursor-pointer",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.obMessages.showModal = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconBase, {
            icon: "envelope",
            class: "envelop-icon"
          }),
          (_ctx.obMessages.unreadMessageIds.length)
            ? (_openBlock(), _createBlock(_component_IconBase, {
                key: 0,
                icon: "circle-filled",
                class: "unread-messages animate-pulse-object"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disabled"]),
      (_ctx.updatesStore().hasRiceCookerUpdate)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["updates cursor-pointer", 
                _ctx.splitPaymentStore.isPartiallyPaid ? '!cursor-not-allowed' : ''
            ]),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (
                !_ctx.splitPaymentStore.isPartiallyPaid
                    ? _ctx.updatesStore().setRiceCookerUpdateModalIsVisible(true)
                    : null
            ))
          }, [
            _createVNode(_component_IconBase, {
              icon: "sync",
              class: "sync-icon"
            }),
            _createVNode(_component_IconBase, {
              icon: "circle-filled",
              class: "new-update animate-pulse-object"
            })
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ButtonBase, {
      url: "https://foodticketnl.my.site.com/foodticketsupport",
      "is-external-url": true,
      target: "_blank",
      class: "helpcenter cursor-pointer flex flex-col flex-nowrap",
      variant: "text",
      disabled: _ctx.isOffline
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_IconBase, {
            icon: "helpcenter",
            class: "helpcenter-icon"
          }),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.translate("helpcenter")), 1)
        ])
      ]),
      _: 1
    }, 8, ["disabled"]),
    (!_ctx.multiLocation.isActive)
      ? (_openBlock(), _createBlock(_component_UserData, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.obMessages.showModal)
      ? (_openBlock(), _createBlock(_component_MessageModal, {
          key: 3,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.obMessages.showModal = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}